// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-page-js": () => import("./../../../src/pages/orderPage.js" /* webpackChunkName: "component---src-pages-order-page-js" */),
  "component---src-pages-sold-out-js": () => import("./../../../src/pages/soldOut.js" /* webpackChunkName: "component---src-pages-sold-out-js" */)
}

